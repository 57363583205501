import React from "react";

// css
import "./FrontGuide.css";

// component
import FrontBreadcrumb from "src/components/Front/Breadcrumb";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import AddLine from "src/assets/icon_line_green.png";
import Jcoin from "src/assets/activity/icon_jcoin.png";

const FrontGuide = () => {
  return (
    <div style={{ marginBottom: 50 }}>
      <FrontBreadcrumb
        link={[
          { href: '', name: "我是消費者" }
        ]}
      />

      <a
        className="btn-floating jcoin"
        href="/installment/activity"
      >
        <img src={Jcoin} alt="jcoin" />
      </a>

      <a
        className="btn-floating line"
        href="https://line.me/R/ti/p/@022vjgux"
      >
        <img src={AddLine} alt="line jetshop" />
      </a>

      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
    </div>
  );
}

export default FrontGuide;
