import React from "react";

// antd
import { Row, Col } from "antd";

import jetDaiLogo from "src/assets/JetDai_logo_text.png";
import badgeHot from "src/assets/badge_hot.png";
import btnPointer from "src/assets/btn_pointer.png";

const Section1 = () => {
  return (
    <div className="frontJetDaiSection1">
      {/* Section1-1 */}
      <Row>
        <Col className="frontJetDaiTitle" span={24} xl={22} lg={22} md={22} sm={22}>
          <div className="text-left">
            <div className="imgTitle">
              <img className="logo" src={jetDaiLogo} alt="JetDai Logo" />
              <img className="badge" src={badgeHot} alt="hot" />
            </div>
            <div className="intro">
              <h3 className="front">
                <p className="mb-none">3C商品現金急救，手機、筆電通通行！</p>
                <p className="mb-none">信用無障礙，Jet貸是您的資金救星</p>
                <p>立即申請，一天內快速撥款</p>
              </h3>
            </div>
            <div className="btn">
              <a href="/about/jetdai/apply-step" target="_blank" rel="noreferrer" onClick={() => { return "gtag_report_conversion('https://jetshop.tw/about/jetdai/apply-step')" }}>
                <button className="frontBtn orange" style={{ width: 180 }}>
                  我要立即申辦
                  <img src={btnPointer} alt="pointer" style={{ width: 20, marginLeft: 6 }} />
                </button>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Section1;
