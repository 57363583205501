import React from "react";

// antd & css
import { Row, Col } from "antd";
import "../FrontJetDai.css";

// component
import FrontBreadcrumb from "src/components/Front/Breadcrumb";
import AnimatedArrow from "src/components/Front/Arrow/AnimatedArrow";

import icon06 from "src/assets/jetdai/common/icon_06.png";
import icon07 from "src/assets/jetdai/common/icon_07.png";
import icon08 from "src/assets/jetdai/common/icon_08.png";
import icon09 from "src/assets/jetdai/common/icon_09.png";
import apply_step_01 from "src/assets/jetdai/common/apply_step_01.png";
import apply_step_02 from "src/assets/jetdai/common/apply_step_02.png";
import apply_step_03 from "src/assets/jetdai/common/apply_step_03.png";
import apply_step_04 from "src/assets/jetdai/common/apply_step_04.png";
import apply_step_05 from "src/assets/jetdai/common/apply_step_05.png";
import apply_step_06 from "src/assets/jetdai/common/apply_step_06.png";
import apply_step_07 from "src/assets/jetdai/common/apply_step_07.png";

const boxes = [
  {
    icon: icon06,
    color: "orange",
    content: "加入Jet貸Line線上客服",
    target: "official-line",
  },
  {
    icon: icon07,
    color: "red",
    content: "專員協助推薦方案及提供申請連結",
    target: "project-assist",
  },
  {
    icon: icon08,
    color: "green",
    content: "線上填寫申請資料",
    target: "apply-online",
  },
  {
    icon: icon09,
    color: "blue",
    content: "審核完成，一個工作天後入帳",
    target: "approval",
  },
];

const FrontGuide = () => {
  return (
    <div style={{ marginBottom: 50, fontSize: "16px" }}>
      <FrontBreadcrumb
        link={[
          { href: '/about/jetdai', name: "Jet貸" },
          { href: '', name: "申辦說明" }
        ]}
      />
      
      <div>
        <Row justify="center">
          <Col className="jetdai-faq-big-title" span={22}>
            <div className="intro">
              <h2 className="front mt-none mb-none">
                申辦Jet貸，4個步驟快速完成，線上申請超便利！
              </h2>
            </div>
          </Col>
          {
            boxes.map((item, idx) => (
              <Col className="d-flex justify-center" span={11} xl={4} lg={5} md={5} sm={6} key={idx} style={{ marginTop: 30 }}>
                <a href={'#' + item.target}>
                  <div className="frontApplyBox">
                    <img src={item.icon} alt={item.content} />
                  </div>
                  <div className="frontApplyContent d-flex justify-center">
                    <h4 className="front">{item.content}</h4>
                  </div>
                </a>
              </Col>
            ))
          }
        </Row>

        <div className="text-left">
          <Row align="center">
            <Col span={23} md={21} sm={21} xs={21}>
              <div className="jetdai-faq-title">
                <h2>步驟詳細說明</h2>
              </div>

              <div className="jetdai-faq-content">
                <div id="official-line">
                  <div className="list-title">
                    1. 加入Line "Jet貸線上客服"，專員會協助您申辦適合的方案
                  </div>
                  <a href="https://lin.ee/E7mTEiS" target="_blank" rel="noreferrer" onClick={() => { return "gtag_report_conversion('https://jetshop.tw/about/jetdai/apply-step')" }}>
                    <button className="frontBtn" style={{ width: 165, margin: "10px 0 20px" }}>
                      {"專員線上諮詢 >>"}
                    </button>
                  </a>
                </div>
                <div className="apply-step d-flex justify-start align-start flex-wrap">
                  <div className="text-center">
                    <img src={apply_step_01} alt="商品貸申貸步驟1-1" />
                    <p className="mb-none">ID搜尋：@jet0123</p>
                  </div>
                  <AnimatedArrow />
                  <img src={apply_step_02} alt="商品貸申貸步驟1-2" />
                </div>

                <div className="list-title" id="project-assist">
                  2. 諮詢及評估最適方案，確認後，專員會提供您申辦連結 或 您可以點選選單中 "申請入口" 進入申辦畫面
                </div>
                <div className="apply-step d-flex justify-start align-start flex-wrap">
                  <div className="text-left" style={{ width: 200 }}>
                    <img src={apply_step_03} alt="商品貸申貸步驟2-1" />
                    <p className="highlight-text mb-none">* 加入後，請您先發送訊息至對話框，這樣我們才能回覆您喔！</p>
                  </div>
                  <AnimatedArrow />
                  <img src={apply_step_04} alt="商品貸申貸步驟2-2" />
                </div>

                <div className="list-title" id="apply-online">
                  3. 依據申辦畫面所需資料填入對應訊息，完成後您可以到會員中心或是詢問專員訂單審核進度
                </div>
                <div className="apply-step d-flex justify-start align-start flex-wrap">
                  <img src={apply_step_05} alt="商品貸申貸步驟3-1" />
                  <AnimatedArrow />
                  <img src={apply_step_06} alt="商品貸申貸步驟4-2" />
                </div>

                <div className="list-title" id="approval">
                  4. 專員會依照您方便的時間進行照會審核，若資料都齊全，您的訂單可在30分鐘內完成審核，申辦金額將在一個工作天後匯進您的指定帳戶
                </div>
                <div className="apply-step d-flex justify-start align-start flex-wrap">
                  <img src={apply_step_07} alt="商品貸申貸步驟4" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default FrontGuide;
