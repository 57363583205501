import React,  { useState } from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivJcoin from "src/components/Front/Divider/DivJcoin";

// image
import gift01 from "src/assets/jetdai/jetdai-activity/gift_01.png";
import gift02 from "src/assets/jetdai/jetdai-activity/gift_02.png";
import gift03 from "src/assets/jetdai/jetdai-activity/gift_03.png";
import divBg01 from "src/assets/jetdai/common/div_bg_01.png";
import divBg02 from "src/assets/jetdai/common/div_bg_02.png";
// import divBg03 from "src/assets/jetdai/common/div_bg_03.png";
// import divBg04 from "src/assets/jetdai/div_bg_04.png";


const Section2 = () => {
  const gifts = [
    {
      id: 1,
      icon: gift01,
      name: 'Apple 第十代 iPad 10.9吋 64G',
      desc: '',
      price: '14,900'
    },
    {
      id: 2,
      icon: gift02,
      name: '紫金堂UNC時光逆齡組',
      desc: '養膚淨白精露皂、茉莉菁純全效精華100ml、皮秒前導精華 50ml、乳香賦活精萃 50ml、植萃奇肌賦活油 30ml',
      price: '27,680'
    },
    {
      id: 3,
      icon: gift03,
      name: 'Philips IPS 窄邊框顯示器',
      desc: '',
      price: '2,999'
    },
  ];

  const projects = [
    {
      title: "雙節優惠方案",
      bg: divBg01,
      color: "red",
      cardInfo: ["5萬 / 36期", "月付$703起"],
      cardNote: "手機電腦換5萬，3分鐘申辦完成， 資料備妥一天內立即撥款",
      content: "全程線上快速申辦，審核效率全台最速，20分鐘快速審核，對保費最高可折扣817元，核准後一個工作天內立即撥款。",
      fundRange: "$10,000~$50,000",
      periods: "6、9、12、18、24、36期",
      conditions: [
        "持有中華民國國民身分證",
        "須備妥身分證件",
        "無重大信用瑕疵",
      ],
    },
    {
      title: "U質社會人方案",
      bg: divBg02,
      color: "orange",
      cardInfo: ["3萬 / 24期", "月付$941起"],
      cardNote: "3萬額度快速借，對保費減半， 年利率只要6.6%起！",
      content: "只要您任職於資本額三千萬(含)以上的公司，持勞健保、薪轉或收入證明符合條件就能免徵保人、對保費50% off、利率狂降只要6.6%，超多福利等你拿！",
      fundRange: "$10,000~$30,000",
      periods: "6、9、12、18、24期",
      conditions: [
        "限滿18歲之本國國民",
        "持有中華民國國民身分證",
        "須備妥身分證件及相關職業證明",
        "在職、提供近三個月薪轉明細、存摺封面",
        "近三個月勞健保證明(健保快易通/投保證明)",
        "資本額認定以經濟部商工登記公示資料查詢結果為依據",
        "無重大信用瑕疵",
      ],
    },
    // {
    //   title: "客製化電腦方案",
    //   bg: divBg03,
    //   color: "blue",
    //   cardInfo: ["8萬 / 36期", "月付$703起"],
    //   cardNote: "最高可申辦8萬元，快速解決您的現金需求，開啟無限可能",
    //   content: "客製化電腦高額方案，免抵押擔保，在您最需要的瞬間助您一臂之力，陪憂解難，打造美好未來。",
    //   fundRange: "$10,000~$80,000",
    //   periods: "6、9、12、18、24、30、36期",
    //   conditions: [
    //     "限滿18歲之本國國民",
    //     "持有中華民國國民身分證",
    //     "須備妥身分證件，勞/健保證明為加分條件",
    //   ],
    // },
    // {
    //   title: "老友優貸方案",
    //   bg: divBg04,
    //   color: "green",
    //   cardInfo: ["5萬 / 36期", "月付$703起"],
    //   cardNote: "手機、筆電電腦都能申請，優待會員，對保費用免費！",
    //   content: "只要您是Jetshop會員，申請此方案對保費用免費，最高可省1,000元！",
    //   fundRange: "$10,000~$50,000",
    //   periods: "6、9、12、18、24、30、36期",
    //   conditions: [
    //     "限滿18歲之本國國民",
    //     "持有中華民國國民身分證",
    //     "須備妥身分證件，勞/健保證明為加分條件",
    //     "需為Jetshop會員",
    //   ],
    // },
  ];

  const [isGift, setIsGift] = useState(false);

  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={20} id="activity-description">
        <FrontDivJcoin title={"　方案說明　"} />
      </Col>

      <Col span={22}>
        <h3 className="front">
          <p className="mb-none jetdai-activity-bg-text">八月雙節特別優惠，感恩及感動一齊滿足～</p>
          <p className="mb-none jetdai-activity-bg-text">8/5~8/31 期間申辦活動優惠方案，多樣好康福利等你挖掘</p>
          {/* <p className="mb-none jetdai-activity-bg-text">『免徵保人』『對保費6折』『新戶申請送50元回饋金<span className="jetdai-small-text">起</span>』</p> */}
          <p className="mb-none jetdai-activity-bg-text">『免徵保人』『對保費最高可折817元』『利率最低6.6%起』</p>
        </h3>
      </Col>

      { isGift &&
        gifts.map(item => (
          <Col span={22} lg={6} md={6} sm={22} key={item.id} style={{ marginTop: 20}}>
            <div className="jetdai-activity-gift">
              <img src={item.icon} alt={item.name} />
              <div className="content-name">
                {item.name}
              </div>
              <div>
                {item.desc}
              </div>
              <div className="content-price">
                {`定價： ${item.price}元`}
              </div>
            </div>
          </Col>
        ))
      }

      <Col span={22} style={{ marginTop: 20 }}>
        {
          projects.map((item, idx) => (
            <Row justify="center" gutter={[16, 8]} key={idx} style={{ marginTop: 30 }}>
              <Col span={22} xl={6} lg={6} md={6} sm={22}>
                <div className={`d-flex justify-center align-center frontJetDaiProject ${item.color}`}>
                  <div>
                    <h3 className="front text-white">{item.title}</h3>
                    <h2 className="front mt-none mb-none text-white">{item.cardInfo[0]}</h2>
                    <h2 className="front mt-none text-white">{item.cardInfo[1]}</h2>
                    <p className="text-white">{item.cardNote}</p>
                    <a href="/about/jetdai/apply-step" target="_blank" rel="noreferrer" onClick={() => { return "gtag_report_conversion('https://jetshop.tw/about/jetdai/apply-step')" }}>
                      <button className="frontBtn-transparent" style={{ margin: "10px 0" }}>
                        {"洽詢專員申請"}
                      </button>
                    </a>
                  </div>
                </div>
              </Col>
              <Col span={22} xl={14} lg={14} md={14} sm={14}>
                <div className="projectDetail">
                  <h3 className="front mb-none">
                    {item.title}
                  </h3>
                  <p>{item.content}</p>
                  <p className="quote mb-none">申請額度：{item.fundRange}</p>
                  <p className="quote mb-none">申請期數：{item.periods}</p>
                  <p className="quote mb-none">申請條件：</p>
                  <ul>
                    {item.conditions.map((cond, i) => (
                        <li key={i}>{cond}</li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          ))
        }
      </Col>
    </Row>
  );
}

export default Section2;
