import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import icon06 from "src/assets/jetdai/common/icon_06.png";
import icon07 from "src/assets/jetdai/common/icon_07.png";
import icon08 from "src/assets/jetdai/common/icon_08.png";
import icon09 from "src/assets/jetdai/common/icon_09.png";

const boxes = [
  {
    icon: icon06,
    color: "orange",
    content: "加入Jet貸Line線上客服"
  },
  {
    icon: icon07,
    color: "red",
    content: "專員協助推薦方案及提供申請連結"
  },
  {
    icon: icon08,
    color: "green",
    content: "線上填寫申請資料"
  },
  {
    icon: icon09,
    color: "blue",
    content: "審核完成，一個工作天後入帳"
  },
];

const Section2 = () => {
  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          申請步驟
        </h2>
        <FrontDivCenter />
        <a href="/about/jetdai/apply-step" target="_blank" rel="noreferrer" onClick={() => { return "gtag_report_conversion('https://jetshop.tw/about/jetdai/apply-step')" }}>
          <button className="frontBtn" style={{ width: 180, marginTop: 10 }}>
            {"聯繫專員諮詢方案"}
          </button>
        </a>
      </Col>
      {
        boxes.map((item, idx) => (
          <Col className="d-flex justify-center" span={11} xl={4} lg={5} md={5} sm={6} key={idx} style={{ marginTop: 20 }}>
            <div>
              <div className="frontApplyBox">
                <img src={item.icon} alt={item.content} />
              </div>
              <div className="frontApplyContent d-flex justify-center">
                <h4 className="front">{item.content}</h4>
              </div>
            </div>
          </Col>
        ))
      }
    </Row>
  );
}

export default Section2;
