import React from "react";

// antd
import { Row, Col } from "antd";

import icon01 from "src/assets/jetdai/jetdai-activity/icon.png";
import icon02 from "src/assets/jetdai/jetdai-activity/icon.png";
import icon03 from "src/assets/jetdai/jetdai-activity/icon.png";
import icon04 from "src/assets/jetdai/jetdai-activity/icon.png";

const pageContents = [
  {
    id: 1,
    title: "方案說明",
    icon: icon01,
    color: "red",
    target: "activity-description",
  },
  {
    id: 2,
    title: "操作步驟",
    icon: icon02,
    color: "orange",
    target: "step",
  },
  {
    id: 3,
    title: "常見問題",
    icon: icon03,
    color: "green",
    target: "FAQ",
  },
  {
    id: 4,
    title: "活動規則",
    icon: icon04,
    color: "blue",
    target: "rule",
  },
];

const Section1 = () => {
  return (
    <div>
      {/* Section1 */}
      <Row justify="center" style={{ marginTop: 50 }}>
        {
          pageContents.map(item => (
            <Col className="d-flex justify-center" key={item.id} span={12} lg={4} md={6} sm={6} style={{ marginTop: 20 }}>
              <div className="jetdai-activity-content">
                <a href={'#' + item.target}>
                  <img src={item.icon} alt={item.title} />
                  <div className="title text-center title">
                    {item.title}
                  </div>
                </a>
              </div>
            </Col>
          ))
        }
      </Row>
    </div>
  );
}

export default Section1;
