import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import icon01 from "src/assets/jetdai/common/icon_01.png";
import icon02 from "src/assets/jetdai/common/icon_02.png";
import icon03 from "src/assets/jetdai/common/icon_03.png";
import icon04 from "src/assets/jetdai/common/icon_04.png";
import icon05 from "src/assets/jetdai/common/icon_05.png";

const boxes = [
  {
    title: "費用透明",
    icon: icon05,
    content: `<p>
      <span class="highlight-first-text">0</span>
      代辦費，平台服務費收取透明合理
    </p>`,
  },
  {
    title: "撥款快速",
    icon: icon01,
    content: `<p>
      <span class="highlight-first-text">1</span>
      個工作天內撥款，應對緊急資金需求
    </p>`,
  },
  {
    title: "審核方便",
    icon: icon03,
    content: `<p>
      <span class="highlight-first-text">2</span>0
      分鐘審件，線上申請不需提供實體手機/筆電
    </p>`,
  },
  {
    title: "條件彈性",
    icon: icon04,
    content: `<p>
      <span class="highlight-first-text">3</span>6
      期最高8萬，信用小白、信用瑕疵都可申請
    </p>`,
  },
  {
    title: "安心保障",
    icon: icon02,
    content: "申辦保密，無代辦個人資料不外洩"
  },
];

const Section2 = () => {
  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          最方便的即時資金解決方案
        </h2>
        <FrontDivCenter />
        <a href="/about/jetdai/faq" target="_blank" rel="noreferrer" onClick={() => { return "gtag_report_conversion('https://jetshop.tw/about/jetdai/faq')" }}>
          <button className="frontBtn" style={{ width: 165, marginTop: 10 }}>
            {"商品貸懶人包 >>"}
          </button>
        </a>
      </Col>
      {
        boxes.map((item, idx) => (
          <Col className="d-flex justify-center" span={11} xl={4} lg={6} md={6} sm={6} key={idx} style={{ marginTop: 20 }}>
            <div className="frontJetDaiResolution">
              <div className="d-flex justify-center">
                <img className="image" src={item.icon} alt={item.title} />
              </div>
              <div className="contentBox" style={{ fontSize: 16 }}>
                <h3 className="front">{item.title}</h3>
                <div dangerouslySetInnerHTML={{__html: item.content}}></div>
              </div>
            </div>
          </Col>
        ))
      }
    </Row>
  );
}

export default Section2;
