import React, { useState, useEffect } from "react";

import { Row, Col, Button } from "antd";

import "src/components/Front/Footer/FrontFooter.css";
import logo from "src/assets/JetShop_logo.png";
import logoText from "src/assets/JetShop_logo_text.png";
import iconLine from "src/assets/icon_line_white.png";
import iconFB from "src/assets/icon_fb_white.png";
import iconYoutube from "src/assets/icon_youtube_white.png";

const FrontFooter = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSmallSize, setIsSmallSize] = useState(windowWidth < 768);

	useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();
  
    return () => window.removeEventListener("resize", onWindowResize);
  }, [windowWidth]);

  const onWindowResize = () => {
    setWindowWidth(window.innerWidth);
    setIsSmallSize(window.innerWidth < 768);
  };

  return (
    <Row className="frontFooter" justify="center">
      <Col span={24} md={20} sm={24} className="footerLeftSection">
        <div className="footerInfo">
          <Row className="footerContentRow">
            <Col span={24} md={10} sm={24}>
              <p className="title">| 聯絡我們</p>
              <p className="content">客服專線：02-2656-0619</p>
              <p className="content">服務時段：</p>
              <ul className="content">
                <li>平日 10:00~21:30(最後審件 21:00)</li>
                <li>週末、例假日 12:00~20:00(最後審件 19:30)</li>
              </ul>
            </Col>
            <Col span={24} md={6} sm={24}>
              <p className="title">| 政策與條款</p>
              <div className="link">
                <p>
                  <a className="content" href="/about/terms/payment">支付使用條款</a>
                </p>
                <p>
                  <a className="content" href="/about/terms/privacy">隱私權條款</a>
                </p>
              </div>
            </Col>
            <Col className="footerService" span={24} md={8} sm={24}>
              <p className="title">| 社群服務</p>
              <a 
                href="https://liff.line.me/1645278921-kWRPP32q/?accountId=jetshop"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button type="link">
                  <img src={iconLine} alt="JetShop Line" />
                </Button>
              </a>
              <a 
                href="https://www.facebook.com/JetShop%E9%87%91%E8%9E%8D%E7%A7%91%E6%8A%80%E6%94%AF%E4%BB%98-108417551446297/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button type="link">
                  <img src={iconFB} alt="JetShop FB" />
                </Button>
              </a>
              <a 
                href="https://www.youtube.com/channel/UCChhrCoDR6aE-0Qt-N44SiQ/featured"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button type="link">
                  <img src={iconYoutube} alt="JetShop Youtube" />
                </Button>
              </a>
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={24} md={4} sm={24}>
        <a href="/">
					{
						isSmallSize ? (
							<img className="frontFooterLogo" src={logoText} alt="JetShop Logo" />
						) : (
							<img className="frontFooterLogo" src={logo} alt="JetShop Logo" />
						)
					}
				</a>
      </Col>
      <Col span={24}>
        <p className="frontFooterText">
          Copyrights © 2019 JetShop. All Rights Reserved. Designed by www.jetshop.tw
        </p>
      </Col>
    </Row>
  );
}

export default FrontFooter;
