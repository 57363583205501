import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/front-FAQ";

// antd & css
import { Row, Col, Tabs, Collapse } from "antd";

// component
import FrontBreadcrumb from "src/components/Front/Breadcrumb";
import AddLine from "src/assets/icon_line_green.png";

const FrontFAQ = () => {
  const [tabs, setTabs] = useState([]);
  const [FAQ, setFAQ] = useState([]);

  // 取出 store 中的 state
  const frontFAQTypeList = useSelector(state => state.frontFAQ.frontFAQTypeList);
  const frontFAQList = useSelector(state => state.frontFAQ.frontFAQList);

  const dispatch = useDispatch();

  const dispatchFunc = (FAQTypeSerialNumber) => dispatch(actionCreators.getFrontFAQ(FAQTypeSerialNumber));

  // step1: 取得FAQ類型
  useEffect(() => {
    dispatch(actionCreators.getFrontFAQType());
  }, []);
  
  // step2: 取得FAQ & FAQ類型配值
  useEffect(() => {
    setTabs(frontFAQTypeList);
    if (frontFAQTypeList && frontFAQTypeList.length > 0) {
      dispatchFunc(frontFAQTypeList[0].FAQTypeSerialNumber);
    }
  }, [frontFAQTypeList]);

  // step3: FAQ配值
  useEffect(() => {
    let data = [];
    if (frontFAQList && frontFAQList.length > 0) {
      data = frontFAQList.filter(faq => faq.FAQStatus === 0)
    };
    // FAQOrder-升序
    data.sort((a, b) => {
      return a.FAQOrder - b.FAQOrder;
    });
    setFAQ(data);
  }, [frontFAQList]);

  const onChangeTab = (e) => {
    dispatchFunc(Number(e));
  };

  return (
    <div>
      <FrontBreadcrumb
        link={[
          { href: '', name: "常見問題" }
        ]}
      />

      <a
        className="btn-floating line"
        href="https://line.me/R/ti/p/@164rvvdv?oat__id=2153313"
      >
        <img src={AddLine} alt="line jetshop" />
      </a>

      <div className="frontTitleImage" style={{ paddingTop: 20 }}>
        <h1 className="frontTitle">
          <span className="linearText">常</span>見問題
        </h1>
      </div>
      <Row align="center" style={{ marginBottom: "50px" }}>
        <Col span={20} md={20} sm={20} xs={20}>
          <Tabs className="front-tab" centered onChange={onChangeTab}>
            {tabs.map(tab => (
              <Tabs.TabPane tab={tab.FAQTypeName} key={tab.FAQTypeSerialNumber}>
                {FAQ.length === 0 && <p>無資料</p>}
                <Collapse>
                  {FAQ.map(item => (
                    <Collapse.Panel header={item.FAQQuestion} key={item.FAQSerialNumber}>
                      <div
                        className="faq-list-content text-left"
                        dangerouslySetInnerHTML={{__html: item.FAQAnswer}}
                      />
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}

export default FrontFAQ;
